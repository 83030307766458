import React, { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Link } from 'react-router-dom';
import { MenuGPC } from "../../api/MenuGPC";
import { useLocation } from 'react-router-dom';
import SideMenuItem from "../SideMenuItem";
import BrickItem from "./BrickItem";

const ClassItem = ({ segment, family, clss }) => {

  const location = useLocation();

  const [bricks, setBricks] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const loadClasses = async (classId) => {
    const res = await MenuGPC.getBricks(classId);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setBricks(res.data);
  };

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[2] || !gpc[2].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[2] == clss.ID);
    }
  }, [location]);

  return (
    <SideMenuItem subItemsLoader={async () => await loadClasses(clss.ID)} isSelected={isSelected}>
      <Link className="text-muted text-base" to={"/ProductsPage/" + segment.ID + "/" + family.ID + "/" + clss.ID}>
        <span title={clss.ID + " : " + clss.Code + " : " + clss.Desc}>{clss.Desc ?? 'NOT DEFINED'}</span>
      </Link>
      {bricks.map((brick, index) => <BrickItem key={index} segment={segment} family={family} clss={clss} brick={brick} />)}
    </SideMenuItem>
  );
};

export default ClassItem;