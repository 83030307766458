import styled from 'styled-components';

import React, { useContext, useEffect, useState } from 'react';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import GVDItemGroup from './GVDItemGroup';
import GVDItemAddGroup from './GVDItemAddGroup';
import { useAppSelector, useAppDispatch } from "../../../store/store";
import {
	gvdEditior,
  fetchGroups
} from "../../../store/slices/gvdEditiorSlice";

const GVDTreeContainerStyle = styled.div`
  margin-top: 10px;  
  .gvd-tree-item-container {
    & .gvd-item-container {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      background-color: white;
      border-bottom: 1px solid #0000004a;
      margin-bottom: 5px;
      cursor: default;
      align-items: center;
      
      &[data-has-children="true"] {
        cursor: pointer;
      }

      &:hover {
        background-color: #52947f;
        color: white;
      }

      & svg + .description {
        margin-left: 5px;
      }

      & .actions-container {
        flex-grow: 1;
        text-align: right;
        display: none;

        & svg {
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      &:hover .actions-container {
        display: block;
      }

      & input.name {
        margin-left: 5px;
        padding: 5px;
        outline: none;
        border: 1px solid #707070;
      }
    }

    & .gvd-tree-item-sub {
      margin-left: 20px;
    }
  }
`;

const GVDTreeContainer = () => {
  const dispatch = useAppDispatch();
  const { groups } = useAppSelector(gvdEditior);

  const { BRICK } = useContext(ContextSelectedBrick);

  useEffect(() => {
    if (!BRICK.trim().length) return;
    dispatch(fetchGroups(BRICK));
  }, [BRICK]);

  return (
    <GVDTreeContainerStyle>
      {BRICK.trim().length > 0 && groups?.length == 0 ? (
        <>
          <h4 style={{ marginBottom: '10px' }}>No records</h4>
          <GVDItemAddGroup />
        </>
      ) : null}
      {groups && groups?.length > 0 && <GVDItemAddGroup />}
      {groups?.map((group, key) => (
        <GVDItemGroup key={key} group={group} />
      ))}
    </GVDTreeContainerStyle>
  );
};

export default GVDTreeContainer;