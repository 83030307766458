import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/store";
import Button from "@mui/material/Button";
import {
    addForm,
    orderSelector,
    fetchCompanyByGtin,
    submitClaimCompany,
    setIndex,
} from "../../store/slices/getStartedSlice";
import GetStartedTab from "./GetStartedTab";

const GetStartedAccountPrefix = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { form, wizardIndex } = useAppSelector(orderSelector);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation(["common"]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        if (!form?.company?.ParentCompanyID) {
            if (!form?.company?.EntityGLN) {
                setMessage("Enter barcode to select product");
                return;
            } else if (!form?.acceptTerms) {
                setMessage("Please accept the term of service");
                return;
            }
            if (form?.company?.EntityGLN) {
                const param = {
                    ...form,
                    EntityGLN: form?.company?.EntityGLN,
                };
                const json = await dispatch(submitClaimCompany(param));
                if (json.status == 204) {
                    dispatch(setIndex(2));
                    navigate("/GetStarted/AccountReview");
                }
            }
        } else {
            dispatch(setIndex(2));
            navigate("/GetStarted/AccountReview");
        }
    };

    const setFormField = (key, value) => {
        let data = Object.assign({}, form);
        data[key] = value;
        dispatch(addForm(data));
    };

    const search = async () => {
        if (form?.company_code) {
            setLoading(true);
            const json = await dispatch(fetchCompanyByGtin(form?.company_code));
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (wizardIndex < 1) {
            navigate("/");
        }
    }, [wizardIndex]);

    const back = () => {
        dispatch(setIndex(0));
        navigate("/GetStarted/AccountDetails");
    };

    return (
        <div className="w-full max-w-7xl mx-auto py-10">
            <GetStartedTab />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 py-3">
                    <h3>{t("common:company_heading").toString()}</h3>
                    <div className="flex items-center gap-2 mt-3">
                        <input
                            {...register("company_code")}
                            onChange={(e) =>
                                setFormField("company_code", e.target.value)
                            }
                            type="text"
                            value={form?.company_code || ""}
                            placeholder="Enter GTIN"
                            className="rounded-md w-full max-w-xs relative px-3 py-2 shadow-sm"
                        />

                        <button
                            disabled={loading}
                            onClick={search}
                            className="rounded text-white bg-primary hover:bg-lightPrimary relative px-3 py-2 focus:outline-none"
                        >
                            Search
                        </button>
                    </div>
                    {errors.company_code && (
                        <span className="text-red-500">
                            {errors.company_code?.message}
                        </span>
                    )}

                    {form?.company?.EntityGLN && (
                        <>
                            <div className="mt-3">
                                <h5>
                                    {form?.company?.CompanyName} -{" "}
                                    {form?.company?.EntityGLN}{" "}
                                </h5>
                                {form?.company?.isAlreadyClaimed && (
                                    <p className="text-red-500">
                                        {"This Company alreday claimed"}
                                    </p>
                                )}
                            </div>
                            {!form?.company?.ParentCompanyID && (
                                <div className="flex items-center mt-3">
                                    <label
                                        htmlFor="acceptTerms"
                                        className="cursor-pointer inline-flex items-center text-[15px] font-normal"
                                    >
                                        <input
                                            disabled={
                                                !form?.company?.EntityGLN ||
                                                form?.company?.isAlreadyClaimed
                                            }
                                            value={form?.acceptTerms || false}
                                            onChange={(e) =>
                                                setFormField(
                                                    "acceptTerms",
                                                    e.target.checked
                                                )
                                            }
                                            id="acceptTerms"
                                            type="checkbox"
                                            className={`hidden peer form-check-input ${
                                                errors.acceptTerms
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                        />
                                        <span className="mr-3 peer-checked:bg-gray-800 bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.7px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-gray-800 rounded-sm"></span>
                                        {t(
                                            "common:company_agreed_text"
                                        ).toString()}
                                    </label>
                                </div>
                            )}
                        </>
                    )}

                    {!!message && (
                        <span className="text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">{message}</span>
                    )}

                    <div className="mt-6 flex gap-2">
                        <button
                            onClick={back}
                            className="px-3 py-2 uppercase bg-primary hover:bg-lightPrimary rounded text-white"
                        >
                            {t("common:back_button_text").toString()}
                        </button>

                        <button
                            type="submit"
                            className="px-3 py-2 uppercase bg-primary hover:bg-lightPrimary rounded text-white"
                        >
                            {t("common:next").toString()}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GetStartedAccountPrefix;
