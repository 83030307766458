import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { listProducts } from "../../pages/SupplierProductsPage/API";

export interface ProductsPageState {
  active: any;
  dataSource: any;
  loading: boolean;
  errorMsg: object;
}

export const initialState: ProductsPageState = {
  active: 1,
  loading: false,
  errorMsg: null,
  dataSource: {}
};

export const productsPageSlice = createSlice({
  name: "productsPage",
  initialState,
  reducers: {
    setErrorMsg: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setActive: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.active = payload;
    },
    setLoading: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setDataSource: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.dataSource = payload;
    },
  },
});

export const fetchData = (skip, limit, sortInfo, active, filterValue) => async (dispatch: any) => {
  const json = await listProducts(
    skip,
    limit,
    sortInfo,
    active,
    filterValue
);
  return json;
};

export const {
  setActive,
  setLoading,
  setErrorMsg,
  setDataSource
} = productsPageSlice.actions;

export const productsPage = (state: RootState) => state.productsPage;

export default productsPageSlice.reducer;
