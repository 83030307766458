
import { PaymentElement, AddressElement, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setContactValidate, setShippingValidate, setPaymentValidate, orderSelector  } from "../../store/slices/getStartedSlice";
import { useTheme } from "@mui/material/styles";

export default function GetStartedStripePaymentElement() {

  const theme = useTheme(); 
    const dispatch = useAppDispatch();

    const {
      form,
      annualTurnover
      
    } = useAppSelector(orderSelector);

  const payment = ((annualTurnover || []).filter(i=>i.SystemSelectID==form?.annual_turnover)?.[0] || {});
  

  return (
    <>
    
      {payment?.SystemSelectID &&
      <div className="mb-4">
       <h4 className="font-semibold pb-3">Your subscription</h4>
      <div className="border rounded-md p-3" style={{borderColor:theme.palette.primary.main}}
      >
      As a Foundation Member, you will be charged ${payment?.SelectionPrice} + GST for a twelve month subscription
        </div>
      </div>
      }
     
      
      
      <h4 className="font-semibold pb-3">Contact info</h4>
      <div className="border rounded-md p-3" style={{borderColor:theme.palette.primary.main}}>
       <LinkAuthenticationElement 
       onChange={(e)=>dispatch(setContactValidate(e.complete))}   
       />
      </div>
      
      {/* If collecting shipping */}

      <h4 className="font-semibold py-3">Billing Address</h4>
      <div className="border  rounded-md p-3" style={{borderColor:theme.palette.primary.main}}>
      <AddressElement options={{mode: 'shipping', defaultValues:{address:{country:"AU"}}}}
        onChange={(e)=>dispatch(setShippingValidate(e.complete))} 
      />
      </div>
      
      <h4 className="font-semibold py-3">Payment</h4>

      <div className="border rounded-md p-3" style={{borderColor:theme.palette.primary.main}}>
      <PaymentElement 
        options={{fields:{billingDetails:"never"}}}
        //options={{payment:{billingSameAsShipping:true}}}
        onChange={(e)=>{
          dispatch(setPaymentValidate(e.complete))}
        }   
        />
      </div>
     </>
  );
}