import Axios from "axios";

const getDefaultRequest = () => {
  return Axios.create({
    //baseURL: 'http://127.0.0.1:8000/api/',
    baseURL: "https://api.theproducthub.co/",
  });
};

const getAuthRequest = (token) => {
  return Axios.create({
    //baseURL: 'http://127.0.0.1:8000/api/',
    baseURL: "https://api.theproducthub.co/",
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getValidatedTokenRequest = () => {
  const req = getDefaultRequest();
  req.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = error.response;

      if (isTokenExpired(errorResponse)) {
        return Promise.resolve(true);
      }
      return Promise.error(error);
    }
  );

  const isTokenExpired = (errorResponse) => {
    return true;
  };

  return req;
};

// https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/
// let isAlreadyFetchingAccessToken = false;

// // This is the list of waiting requests that will retry after the JWT refresh complete
// let subscribers = [];

// async function ResetTokenAndReattemptRequest(error) {
//   try {
//     const { response: errorResponse } = error;
//     const { clearTokens } = useAuth();
//     const resetToken = clearTokens.clearTokens(); // Your own mechanism to get the refresh token to refresh the JWT token
//     if (!resetToken) {
//       // We can't refresh, throw the error anyway
//       return Promise.reject(error);
//     }
//     /* Proceed to the token refresh procedure
//     We create a new Promise that will retry the request,
//     clone all the request configuration from the failed
//     request in the error object. */
//     const retryOriginalRequest = new Promise(resolve => {
//       /* We need to add the request retry to the queue
//       since there another request that already attempt to
//       refresh the token */
//       addSubscriber(access_token => {
//         errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
//         resolve(Axios(errorResponse.config));
//       });
//     });
//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true;
//       const response = await Axios({
//         method: 'post',
//         url: `<YOUR TOKEN REFREH ENDPOINT>`,
//         data: {
//           token: resetToken // Just an example, your case may vary
//         }
//       });
//       if (!response.data) {
//         useHistory().push('');
//         return Promise.reject(error);
//       }
//       const newToken = response.data.token;
//       TokenUtils.saveRefreshToken(newToken); // save the newly refreshed token for other requests to use
//       isAlreadyFetchingAccessToken = false;
//       onAccessTokenFetched(newToken);
//     }
//     return retryOriginalRequest;
//   } catch (err) {
//     return Promise.reject(err);
//   }
// }

// function onAccessTokenFetched(access_token) {
//   // When the refresh is successful, we start retrying the requests one by one and empty the queue
//   subscribers.forEach(callback => callback(access_token));
//   subscribers = [];
// }

// function addSubscriber(callback) {
//   subscribers.push(callback);
// }

const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  CONFLICT: 409,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  UNPROCESSABLE_ENTITY: 422,
  SERVER_ERROR: 500,
};

export default getDefaultRequest;
export { getAuthRequest, getValidatedTokenRequest, HTTP_STATUS_CODES };
