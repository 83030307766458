import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import {
  listAccountsApproved,
  listAccountsToBeApproved,
  listAccountsWaitingMoreInfo,
  addCheck,
  approve,
  getSignUpDetail,
  listChecks,
  reject,
} from "../../api/SignupApproval";

export interface SignupApprovalState {
  accsToApprove: any;
  accsApproved: any;
  accsMoreInfo: any;
  signupDetails: any;
  loader: boolean;
  loading: boolean;
  error: object;
  errorMsg: object;
  isAccsToApprove: boolean;
  isAccsApproved: boolean;
  isAccsMoreInfo: boolean
}

export const initialState: SignupApprovalState = {
  accsToApprove: {},
  accsApproved: {},
  accsMoreInfo: {},
  signupDetails: {},
  loader: false,
  loading: false,
  errorMsg: null,
  error: {},
  isAccsToApprove: false,
  isAccsApproved: false,
  isAccsMoreInfo: false,
};

export const signupApprovalSlice = createSlice({
  name: "signupApproval",
  initialState,
  reducers: {
    setAccsToApprove: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsToApprove = payload;
    },
    setAccsApproved: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsApproved = payload;
    },
    setAccsMoreInfo: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsMoreInfo = payload;
    },
    setSignupDetails: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.signupDetails = payload;
    },
    isAccsToApprove: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsToApprove = payload;
    },
    isAccsApproved: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsApproved = payload;
    },
    isAccsMoreInfo: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsMoreInfo = payload;
    },
    setError: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setErrorMsg: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setLoader: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.loader = payload;
    },
    setLoading: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
  },
});

export const fetchAccsToApprove = () => async (dispatch: any) => {
  dispatch(isAccsToApprove(true));
  const json = await listAccountsToBeApproved();
    if (json.status == 200) {
      dispatch(setAccsToApprove(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsToApprove(false));
  return json;
};

export const fetchAccsApproved = () => async (dispatch: any) => {
  dispatch(isAccsApproved(true));
  const json = await listAccountsApproved();
    if (json.status == 200) {
      dispatch(setAccsApproved(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsApproved(false));
  return json;
};

export const fetchAccsMoreInfo = () => async (dispatch: any) => {
  dispatch(isAccsMoreInfo(true));
  const json = await listAccountsWaitingMoreInfo();
    if (json.status == 200) {
      dispatch(setAccsMoreInfo(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsMoreInfo(false));
  return json;
};

export const fetchSignUpDetails = (SignupID: any) => async (dispatch: any) => {
  dispatch(setLoader(true));
  const json = await getSignUpDetail(SignupID);
    if (json.status == 200) {
      dispatch(setSignupDetails(json.data));
    } else {
      dispatch(setSignupDetails(json.message));
    }
    dispatch(setLoader(false));
  return json;
};

export const fetchSignupHistory = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await listChecks(SignupID);
  if (json.status == 200) {
    const currentSignupDetails = getState().signupApproval.signupDetails;
    dispatch(setSignupDetails({ ...currentSignupDetails, signuphistory: json.data }));
  } else {
    dispatch(setError(json.data));
  }
  dispatch(setLoading(false));
  return json;
};

export const addAccount = (SignupID: any, SignupCheck: any, Title: any, Text: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await addCheck(SignupID, SignupCheck, Title, Text);
  dispatch(setLoading(false));
  return json;
};


export const approveAccount = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await approve(SignupID);
  dispatch(setLoading(false));
  return json;
};

export const rejectAccount = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await reject(SignupID);
  dispatch(setLoading(false));
  return json;
};

export const {
  setLoader,
  setError,
  setAccsToApprove,
  setAccsApproved,
  setAccsMoreInfo,
  isAccsToApprove,
  isAccsApproved,
  isAccsMoreInfo,
  setSignupDetails,
  setLoading,
  setErrorMsg
} = signupApprovalSlice.actions;

export const signupApproval = (state: RootState) => state.signupApproval;

export default signupApprovalSlice.reducer;
