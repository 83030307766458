import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
	auth,
	setSucceeded,
	setFailed,
	setProcessing,
	setPwDontMatch,
	newAccount,
} from "../../store/slices/authSlice";
import "./SignUpPage.css";

const SignUpPage = () => {
	const dispatch = useAppDispatch();
	const { failed, succeeded, processing, error, pwDontMatch } =
		useAppSelector(auth);
	const navigate = useNavigate();

	const [nameInput, setNameInput, onNameChange] = useFormFieldOnChange("");
	const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
	const [passwordInput, setPasswordInput, onPasswordChange] =
		useFormFieldOnChange("");
	const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] =
		useFormFieldOnChange("");

	const onNameInputChange = (e) => {
		onNameChange(e);
		hideStatusMessages();
	};

	const onEmailInputChange = (e) => {
		onEmailChange(e);
		hideStatusMessages();
	};

	const onPasswordInputChange = (e) => {
		onPasswordChange(e);
		hideStatusMessages();
	};

	const onConfirmPwInputChange = (e) => {
		onConfirmPwChange(e);
		hideStatusMessages();
	};

	useEffect(() => {
		if (
			passwordInput.trim().length == "" ||
			confirmPwInput.trim().length == ""
		) {
			dispatch(setPwDontMatch(false));
			return;
		}
		dispatch(setPwDontMatch(passwordInput !== confirmPwInput));
	}, [passwordInput, confirmPwInput]);

	const submitForm = async (e) => {
		e.preventDefault();
		if (processing) {
			return;
		}
		if (
			nameInput.trim().length == "" ||
			emailInput.trim().length == "" ||
			passwordInput.trim().length == "" ||
			confirmPwInput.trim().length == ""
		) {
			return;
		}

		if (passwordInput !== confirmPwInput) {
			dispatch(setPwDontMatch(true));
			return;
		}

		hideStatusMessages();

		dispatch(newAccount(nameInput, emailInput, passwordInput));
        if(succeeded){
		resetForm();
        }
	};

	const hideStatusMessages = () => {
		dispatch(setProcessing(false));
		dispatch(setFailed(false));
		dispatch(setSucceeded(false));
		dispatch(setPwDontMatch(false));
	};

	const resetForm = () => {
		setNameInput("");
		setEmailInput("");
		setPasswordInput("");
		setConfirmPwInput("");
		hideStatusMessages();
	};

	const continueAfterRegistration = (e) => {
		e.preventDefault();
		navigate("/");
	};

	const accountCreated = () => {
		return (
			<>
				<p style={{ fontWeight: "normal" }} className="text-black">
					Check your email and follow the instructions to verify your account.
				</p>
				<div className="FormButtonContainer">
					<button
						onClick={continueAfterRegistration}
						className="btn btn-primary btn-rounded"
					>
						Continue
					</button>
				</div>
			</>
		);
	};

	const formContent = () => {
		return (
			<>
				<div className="flex flex-col mb-3">
					<label>First Name</label>
					<input
						type="text"
						placeholder="Enter your name..."
						value={nameInput}
						className="rounded-full border py-1.5 px-2"
						onChange={onNameInputChange}
					/>
				</div>
				<div className="flex flex-col mb-3">
					<label>Email</label>
					<input
						type="text"
						placeholder="Enter your email..."
						value={emailInput}
						className="rounded-full border py-1.5 px-2"
						onChange={onEmailInputChange}
					/>
				</div>
				<div className="flex flex-col mb-3">
					<label>Password</label>
					<input
						type="password"
						placeholder="Enter your password..."
						value={passwordInput}
						className="rounded-full border py-1.5 px-2"
						onChange={onPasswordInputChange}
					/>
				</div>
				<div className="flex flex-col mb-3">
					<label>Confirm your password</label>
					<input
						type="password"
						placeholder="Confirm your password..."
						className="rounded-full border py-1.5 px-2"
						value={confirmPwInput}
						onChange={onConfirmPwInputChange}
					/>
				</div>
				{failed && <div className="text-red-500 font-bold">{error}</div>}
				{pwDontMatch && (
					<div className="text-red-500 font-bold">
						You've entered different passwords!
					</div>
				)}
				{succeeded && (
					<div className="text-red-500 font-bold">
						Congratulations, your account has been created.
						<br />
						Please, read the instructions we've sent to your email on how to
						activate your account.
					</div>
				)}
				<div className="flex align-center gap-2">
					<button
						className={`${
							processing ? "bg-gray-400" : "bg-primary"
						} rounded-full text-white py-1.5 w-full`}
						disabled={processing}
						onClick={submitForm}
					>
						Confirm
					</button>
					<button
						disabled={processing}
						className={`${
							processing ? "bg-gray-400" : "bg-primary"
						} rounded-full text-white py-1.5 w-full`}
						onClick={(e) => {
							e.preventDefault();
							resetForm();
						}}
					>
						Reset
					</button>
				</div>
				<div className="text-right mt-3">
					<Link to="/SignIn">Have an Account Login here</Link>
				</div>
			</>
		);
	};

	return (
		<div className="w-full flex justify-center h-full items-center lg:px-0 px-5 overflow-hidden">
			<div className="w-1/3 mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
				<form>
					<div className="flex flex-col justify-center items-center">
						<img
							className="w-[300px]"
							src="BBA-Logo-TBl-S.png"
							alt="BBA-Logo-TBl-S"
						/>
						<p className="font-bold text-center mb-3 text-lg">
							{!succeeded ? "Create your acount" : "Account created"}
						</p>
					</div>
					{!succeeded ? formContent() : accountCreated()}
				</form>
			</div>
		</div>
	);
};

export default SignUpPage;
