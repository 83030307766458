import React, { useContext, useEffect, useState } from 'react'
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	setWords,
  fetchAssignedWords
} from "../../../../store/slices/gvdValidateSlice";

const AssignedWordsContainer = () => {
  const dispatch = useAppDispatch();
  const { words, processing } = useAppSelector(gvdValidate);

  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail } = useContext(SelectedGVDDetailContext);

  useEffect(() => {
    (BRICK?.trim().length ?? 0) > 0 && fetchWords();
  }, [selectedDetail]);

  useEffect(() => {
    dispatch(setWords([]));
  }, [BRICK]);

  const fetchWords = async () => {
    if (selectedDetail.GroupingID == '' || selectedDetail.VariantID == '' || selectedDetail.DetailID == '') return;
    dispatch(fetchAssignedWords(BRICK, selectedDetail.GroupingID, selectedDetail.VariantID, selectedDetail.DetailID))
  };

  const getNewRow = (word, index) => {
    return (
      <div key={index} className={`p-2 flex flex-row items-center cursor-pointer ${index%2 !== 0 ? 'bg-white' : 'bg-[#cce4dc]'}`}>
        <span className="flex-grow truncate">{word.Words.join(' - ')}</span>
      </div>
    )
  };

  return (
    <>
      <div className="flex-grow bg-white border flex flex-col">
        <span className="p-3 border-b border-b-[#707070] flex flex-col">
          <span className="font-bold">Assigned Words</span>
          {
            selectedDetail.GroupingID && <span className="italic text-black">
              {selectedDetail.GroupingDesc} {selectedDetail.VariantDesc ? '> ' + selectedDetail.VariantDesc : ''} {selectedDetail.DetailDesc ? '> ' + selectedDetail.DetailDesc : ''}
            </span>
          }
        </span>
        <div className="p-2 flex-grow flex flex-col h-0 overflow-y-auto relative">
          {!processing && words.length == 0 ? (
            <div className="h-full flex justify-center items-center">
              <h4>No records</h4>
            </div>
          ) : null}

          {words.map((word, index) => getNewRow(word, index))}

          {processing && (
            <div className="h-full flex justify-center items-center">
              <h4>Loading...</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignedWordsContainer;