import React, { useState } from "react";
import Routes from "./routes/Routes";
import SideMenu from "./dashboard-menu/SideMenu";
import { AuthContext, useAuthState } from "./context/AuthContext";
import "./App.css";
import { Popover,Transition } from "@headlessui/react";
import Footer from "./components/footer/Footer";

const App = () => {
    const authState = useAuthState();

    const [toggle, setToggle] = useState(false);
    const [responsiveToggle, setResponsiveToggle] = useState(false);

    const logout = async () => authState.logoutUser();

    const toggleSideBar = () => {
        setToggle(!toggle);
    };

    const responsiveSideBar = () => {
        setResponsiveToggle(!responsiveToggle);
    }

    const user = authState.user;

    const boxShadow = {
        boxShadow: '0 1px 15px 1px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.08)'
    }

    return (
        <AuthContext.Provider value={authState}>
            <div className="overflow-hidden w-full">
                {authState.isUserLoggedIn && (
                    <SideMenu sideBarToggle={toggle} responsiveToggle={responsiveToggle} />
                )}
                {authState.isUserLoggedIn && responsiveToggle && (
                    <div onClick={()=> setResponsiveToggle(false)} className="lg:hidden block fixed w-[calc(100%-240px)] h-[calc(100vh-5px)] bottom-0 right-0 z-[999999]" />
                )}
                <div>
                    <div
                        className={`${
                            (!authState.isUserLoggedIn || toggle) ? "lg:w-full" : "lg:w-[calc(100%-240px)]"
                        } p-[1rem_2rem_0rem] min-h-screen float-right m-0 w-full bg-[#f9f9f9] transition-all duration-200 ease-in flex flex-col ${
                            !authState.isUserLoggedIn && "!w-full h-screen"
                        }`}
                    >
                        {authState.isUserLoggedIn && (
                            <header className="rounded-md h-16 relative w-full shadow-md flex flex-wrap items-center justify-between bg-white z-10 transition-all duration-200 ease-in p-2">
                                <div className="flex items-center mr-4 w-full justify-between">
                                    {/* toggle sidebar */}
                                    <div
                                        className="cursor-pointer ml-2 lg:block hidden"
                                        onClick={toggleSideBar}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.2}
                                            stroke="currentColor"
                                            className="w-6 h-6 text-black"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                                            />
                                        </svg>
                                    </div>
                                    {/* responsive sidebar toggle */}
                                    <div
                                        className="cursor-pointer ml-2 lg:hidden block"
                                        onClick={responsiveSideBar}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.2}
                                            stroke="currentColor"
                                            className="w-6 h-6 text-black"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                                            />
                                        </svg>
                                    </div>
                                    <div className="flex justify-end items-center gap-2">
                                        {/* FAQ */}
                                        <div>
                                            <a className="has-arrow" href="#">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    width={20}
                                                    height={20}
                                                    className="mr-2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                        {/* Contact us */}
                                        <div>
                                            <a className="has-arrow" href="#">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    width={20}
                                                    height={20}
                                                    className="mr-2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                        {/* Logout */}
                                        <Popover className="relative" as="div">
                                            <Popover.Button className="focus-within:outline-none focus:outline-none focus-visible:outline-none focus-within:border-none focus:border-none focus-visible:border-none">
                                                <div className="w-7 h-7 focus-within:outline-none focus:outline-none focus-visible:outline-none focus-within:border-none focus:border-none focus-visible:border-none">
                                                    <p className="w-full pt-1 rounded-3xl flex justify-center items-center text-white font-bold bg-gray-400 h-full">{user?.NameFirst?.charAt(0)}</p>
                                                </div>
                                            </Popover.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                                className={"relative z-50"}
                                            >
                                                <Popover.Panel className="absolute z-10 bg-white min-w-[150px] py-3 right-0 rounded-md" style={boxShadow}>
                                                    <div className="w-full">
                                                        <div className="w-full">
                                                            <button onClick={logout} className="text-black hover:bg-[#e5e7eb] w-full py-2">
                                                                Logout
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </Popover>
                                    </div>
                                </div>
                            </header>
                        )}
                        <Routes />
                    </div>
                    <Footer toggle={toggle} login={authState.isUserLoggedIn} />
                </div>
            </div>
        </AuthContext.Provider>
    );
};

export default App;
