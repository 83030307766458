import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  let [counter, setCounter] = useState(5);

  const takeToLogin = (e) => {
    e.preventDefault();
    navigate("/SignIn");
  };

  useEffect(() => {

  const countdownInterval = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        navigate("/SignIn")
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [counter]);

  return (
    <div className="w-full flex justify-center h-full items-center lg:px-0 px-5 overflow-hidden">
      <div className="w-1/3 mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl flex justify-center items-center">
        <form>
          <img
            className="w-[200px] mb-4 self-center m-auto"
            src="BBA-Logo-TBl-S.png"
            alt="bba"
          />
          <p className="text-center text-black pb-3 font-semibold">Congratulations, your account has been verified!</p>
          <p className="font-normal">You are being redirected to Login page in <span className="font-bold">{counter}</span> seconds.</p>
          {/* <div className="FormButtonContainer">
            <button onClick={takeToLogin}>Take me to the login</button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default CompleteRegistration;
