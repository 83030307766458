import React, { useState } from 'react';
import DashboardContainer from '../../../dashboard-components/DashboardContainer';
import BrickSearchContainer from './components/BrickSearchContainer';
import WrapperSelectedRawWords from './components/WrapperSelectedRawWords';

import './GVDValidation.scss';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';

const ValidateGVD = () => {

  const [selectedBrick, setSelectedBrick] = useState(null);

  const refreshBrick = () => {
    if (selectedBrick == null) return;
    setSelectedBrick(new String(selectedBrick));
  }

  return (
    <DashboardContainer title={"Validate GVD"}>

      <ContextSelectedBrick.Provider value={{
        BRICK: selectedBrick,
        BrickDesc: '',
        refreshBrick, setSelectedBrick, setSelectedBrick
      }}>

        <div className="flex flex-row items-center">
          <BrickSearchContainer />
        </div>

        <div className="gvd-validation-body flex-grow mt-3 flex flex-row">
          <WrapperSelectedRawWords />
        </div>
      </ContextSelectedBrick.Provider>

    </DashboardContainer>
  );
};

export default ValidateGVD;