import React from "react";
import { Link, useLocation } from "react-router-dom";

const DashboardHeader = ({ title }) => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((path) => path);
    return (
        <div className="flex items-center rounded-sm list-none bg-transparent p-0 my-3">
            <h1 className="text-2xl">{title}</h1>
            <ul className="list-none p-0 m-0">
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames
                        .slice(0, index + 1)
                        .join("/")}`;
                    const isLast = index === pathnames.length - 1;

                    return (
                        <li
                            key={name}
                            className="text-sm inline-block relative py-0 p-2 align-bottom text-muted"
                        >
                            {isLast ? (
                                name
                            ) : (
                                <Link
                                    className="text-[#332e38] after:absolute after:top-[3px] after:right-0 after:content-[''] after:h-4 after:w-px after:bg-[#70657b] after:rounded"
                                    to={routeTo}
                                >
                                    {name}
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default DashboardHeader;
