import React, { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Link } from 'react-router-dom';
import { MenuGPC } from "../../api/MenuGPC";
import { useLocation } from 'react-router-dom';
import SideMenuItem from "../SideMenuItem";
import VariantItem from "./VariantItem";

const GroupingItem = ({ segment, family, clss, brick, grouping }) => {

  const location = useLocation();

  const [variants, setVariants] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const loadVariants = async (groupingId) => {
    const res = await MenuGPC.getVariants(groupingId);
    if (res.status !== HTTP_STATUS_CODES.OK) {
      return;
    }
    setVariants(res.data);
  };

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[4] || !gpc[4].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[4] == grouping.ID);
    }
  }, [location]);

  return (
    <SideMenuItem subItemsLoader={async () => await loadVariants(grouping.ID)} isSelected={isSelected}>
      <Link className="text-muted text-base" to={"/ProductsPage/" + segment.ID + "/" + family.ID + "/" + clss.ID + "/" + brick.ID + "/" + grouping.ID}>
        <span title={grouping.ID + " : " + grouping.Desc}>{grouping.Desc ?? 'NOT DEFINED'}</span>
      </Link>
      {variants.map((variant, index) => <VariantItem key={index} segment={segment} family={family} clss={clss} brick={brick} grouping={grouping} variant={variant} />)}
    </SideMenuItem>
  );
};

export default GroupingItem;