import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { finish, importProds, start } from '../../api/ImportFileProducts';
import { GVDEditorAPI } from '../../dashboard-admin/GVD/GVDEditorAPI';

export interface productState {
  processing: boolean;
  loading: boolean;
  errorMsg: object;
}

export const initialState: productState = {
  processing: false,
  loading: false,
  errorMsg: null
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setErrorMsg: (state: productState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setLoading: (state: productState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    
    setProcessing: (state: productState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
  },
});

export const importStart = () => async (dispatch: any) => {
  const res = await start();
  return res;
};

export const productsImport = (UpdateID, chunck) => async (dispatch: any) => {
  const res = await importProds(UpdateID, chunck);
  return res;
};

export const importFinish = (UpdateID) => async (dispatch: any) => {
  const res = await finish(UpdateID);
  return res;
};

export const {
  setLoading,
  setErrorMsg,
  setProcessing
} = productSlice.actions;

export const product = (state: RootState) => state.product;

export default productSlice.reducer;
