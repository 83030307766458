import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const listAccountsToBeApproved = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsToBeApproved');
  } catch (error) {
    return error;
  }
};

const listAccountsApproved = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsApproved');
  } catch (error) {
    return error;
  }
};

const listAccountsWaitingMoreInfo = async () => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listAccountsWaitingMoreInfo');
  } catch (error) {
    return error;
  }
};

const getSignUpDetail = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/getSignUpDetail/' + SignupID);
  } catch (error) {
    return error;
  }
};

const listChecks = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).get('signupapproval/listChecks/' + SignupID);
  } catch (error) {
    return error;
  }
};

const addCheck = async (SignupID, SignupCheck, Title, Text) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/addCheck/' + SignupID, {
      SignupID, SignupCheck, Title, Text
    });
  } catch (error) {
    return false;
  }
};

const approve = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/approve/' + SignupID);
  } catch (error) {
    return error;
  }
};

const reject = async (SignupID) => {
  try {
    return await getAuthRequest(getToken()).post('signupapproval/reject/' + SignupID);
  } catch (error) {
    return error;
  }
};

export {
  listAccountsToBeApproved,
  listAccountsApproved,
  listAccountsWaitingMoreInfo,
  listChecks,
  addCheck,
  getSignUpDetail,
  approve,
  reject
};