import React from "react";

const Footer = ({ login,toggle }) => {
    if (!login) {
        return (
            <div className="bg-black p-5 fixed bottom-0 w-full">
                <p className="text-gray-400">Powered by ProductHub</p>
            </div>
        );
    }

    return (
        <div className={`bg-black p-5 transition-all duration-200 ease-in float-right ${(toggle) ? "lg:w-full" : "lg:w-[calc(100%-240px)]"}`}>
            <p className="text-gray-400">Powered by ProductHub</p>
        </div>
    );
};

export default Footer;
