import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import "./SignInPage.css";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { auth, signIn, setError } from "../../store/slices/authSlice";

export default function SignInPage(props) {
	const dispatch = useAppDispatch();
	const userContext = useAuthContext();
	const { failed, processing, error } = useAppSelector(auth);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	async function handleSubmit(event) {
		event.preventDefault();

		if (processing) {
			return;
		}

		const req = await dispatch(signIn(email, password));
		if (req && req.status === 200) {
			setEmail("");
			setPassword("");
            dispatch(setError(""))
			userContext.setUser(req.data.user);
			userContext.setToken({
				access_token: req.data.access_token,
			});
		}
	}

	return (
		<div className="w-full flex justify-center h-full items-center lg:px-0 px-5 overflow-hidden">
			<div className="w-1/3 mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
				<form onSubmit={handleSubmit}>
					<div className="flex flex-col justify-center items-center">
						<img
							className="w-[300px]"
							src="BBA-Logo-TBl-S.png"
							alt="BBA-Logo-TBl-S"
						/>
						<p className="font-bold text-center text-lg">Welcome back!</p>
					</div>

					<div className="flex flex-col mb-3">
						<label>Email</label>
						<input
							className={`rounded-full border py-1.5 px-2 ${
								failed ? "border-red-500" : "border-gray"
							}`}
							type="text"
							placeholder="Enter your email..."
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="flex flex-col">
						<label>Password</label>
						<input
							className={`rounded-full border py-1.5 px-2 ${
								failed ? "border-red-500" : "border-gray"
							}`}
							type="password"
							placeholder="Enter your password..."
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					{error.length > 2 && <div className="text-red-500 font-bold mt-2">{error}</div>}
					<div className="flex justify-between items-center mt-3">
						<button
							className={`rounded-full text-white py-1.5 w-24 ${
								processing ? "bg-gray-400" : "bg-primary"
							}`}
							disabled={processing}
							onClick={handleSubmit}
						>
							Login
						</button>
						<div className="flex gap-2 items-center">
							<Link to="/ForgotPassword">Forgot Password?</Link>|
							<Link to="/SignUp">Create an Account</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
