import React from "react";
import { Link } from "react-router-dom";
import SideMenuGPC from "./SideMenuGPC";
import SideMenuItem from "./SideMenuItem";

const SideMenuAdmin = () => {
    return (
        <>
            {/* this is the official side menu  */}
            {/* <SideMenuItem>
        <Link to="/">Main</Link>
        <SideMenuItem>
          <Link to="/GVDEditor">GVD Editor</Link>
          <Link to="/ValidateGVD">Validate GVD</Link>
          <Link to="/ProductImport">Product Import</Link>
          <Link to="/ProductScan">Product Scan</Link>
          <Link to="/SignupApproval">Signup Approval</Link>
          <Link to="/">Overrides</Link>
          <Link to="/">Analytics</Link>
          <Link to="/EODReport">EOD Report</Link>
        </SideMenuItem>
      </SideMenuItem> */}
            <li className="Ul_li--hover">
                <Link
                    className="has-arrow w-[220px] mx-3 h-9 !flex items-center"
                    to="/"
                >
                    <i className="i-Home-Window text-lg mr-2 text-muted"></i>
                    <span className="item-name text-base text-muted">Main</span>
                </Link>
                <ul className="mm-collapse space-y-4 ml-2 mt-2">
                    <li className="item-name">
                        <Link
                            to="/GVDEditor"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                            </svg>

                            <span className="item-name text-base">GVD Editor</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/ValidateGVD"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>

                            <span className="item-name text-base">Validate GVD</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/ProductImport"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                            </svg>

                            <span className="item-name text-base">Product Import</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/ProductScan"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                                />
                            </svg>

                            <span className="item-name text-base">Product Scan</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/SignupApproval"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                />
                            </svg>

                            <span className="item-name text-base">Signup Approval</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                            </svg>

                            <span className="item-name text-base">Overrides</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                                />
                            </svg>

                            <span className="item-name text-base">Analytics</span>
                        </Link>
                    </li>
                    <li className="item-name">
                        <Link
                            to="/EODReport"
                            className="mx-3 flex gap-2 items-center text-muted"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 text-muted h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
                                />
                            </svg>

                            <span className="item-name text-base">EOD Report</span>
                        </Link>
                    </li>
                </ul>
            </li>
            <SideMenuGPC />
        </>
    );
};

export default SideMenuAdmin;
