import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HTTP_STATUS_CODES } from "../api/API";
import { MenuGPC } from "../api/MenuGPC";
import SegmentItem from "./SideMenuGPC/SegmentItem";
import SideMenuItem from "./SideMenuItem";

const SideMenuGPC = () => {
    const [segments, setSegments] = useState([]);

    const loadSegments = async () => {
        const res = await MenuGPC.getSegments();
        if (res.status !== HTTP_STATUS_CODES.OK) {
            return;
        }
        setSegments(res.data);
    };

    return (
        // <SideMenuItem subItemsLoader={loadSegments}>
        <li className="Ul_li--hover">
            <Link
                onClick={loadSegments}
                className="has-arrow w-[220px] mx-3 h-9 !flex items-center"
                to="/ProductsPage"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 mr-2 text-muted h-5"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                    />
                </svg>
                <span className="item-name text-base text-muted">Products</span>
            </Link>
            <ul className="mm-collapse space-y-4 ml-2 mt-2">
                <li className="item-name">
                    <Link
                        to="/ProductsPage"
                        className="mx-3 flex gap-2 items-center text-muted"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            width={20}
                            height={20}
                            className="mr-2 text-muted"
                            strokeWidth="1.5"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                        </svg>
                        <span className="item-name text-base">Home</span>
                    </Link>
                </li>
                {segments.map((segment, index) => (
                    <SegmentItem key={index} segment={segment} />
                ))}
            </ul>
        </li>
        // </SideMenuItem>
    );
};

export default SideMenuGPC;
