import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
auth,
setSucceeded,
setFailed,
setProcessing,
forgotPassword
} from "../../store/slices/authSlice";

import "./ForgotPassword.css";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { failed, succeeded, processing } = useAppSelector(auth);
    const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");

    const onEmailInputChange = (e) => {
        onEmailChange(e);
        hideStatusMessages();
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (processing || emailInput.trim().length == "") {
            return;
        }
        hideStatusMessages();
        dispatch(forgotPassword(emailInput));
    };

    const hideStatusMessages = () => {
        dispatch(setProcessing(false));
        dispatch(setFailed(false));
        dispatch(setSucceeded(false));
    };

    const passwordResetLinkSent = () => {
        return (
            <p className="text-black" style={{ fontWeight: "normal" }}>
                Check your email and follow the instructions to reset your
                password.
            </p>
        );
    };

    const formContent = () => {
        return (
            <>
                <div className="flex flex-col mb-3">
                    <label>Enter your email below</label>
                    <input
                        type="email"
                        placeholder="Enter your email..."
                        className="rounded-full border py-1.5 px-2 border-gray"
                        value={emailInput}
                        onChange={onEmailInputChange}
                    />
                </div>
                {failed && (
                    <div className="text-red-500">
                        An error has occurred while processing the password
                        reset request.
                    </div>
                )}
                <div className="flex items-center gap-2">
                    <button
                        disabled={processing}
                        className={`${processing ? 'bg-gray-400' : 'bg-primary'} rounded-full text-white py-1.5 w-full`}
                        onClick={submitForm}
                    >
                        Reset password
                    </button>
                    <button
                        disabled={processing}
                        className={`${processing ? 'bg-gray-400' : 'bg-primary'} rounded-full text-white py-1.5 w-full`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/SignIn");
                        }}
                    >
                        Go to Login
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="w-full flex justify-center h-full items-center lg:px-0 px-5 overflow-hidden">
            <div className="w-1/3 mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
                <form>
                    <div className="flex flex-col justify-center items-center">
                        <img
                            className="w-[300px]"
                            src="BBA-Logo-TBl-S.png"
                            alt="BBA-Logo-TBl-S"
                        />
                        <p className="font-bold text-center mb-3 text-lg">
                            {!succeeded
                                ? "Forgot you password?"
                                : "We've sent you a password reset link"}
                        </p>
                    </div>
                    {!succeeded ? formContent() : passwordResetLinkSent()}
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
