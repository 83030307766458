import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import DashboardContainer from "../../dashboard-components/DashboardContainer";
import "./DashboardHome.scss";
import SupplierProduct from "../../dashboard-supplier/Product/components/SupplierProduct";
import SupplierProductsPage from "../../pages/SupplierProductsPage/ProductsPage";

const DashboardHome = (props) => {
    const userAuth = useAuthContext();
    const user = userAuth.user;

    const navigate = useNavigate();

    const getStarted = (e) => {
        e.preventDefault();

        if (userAuth.isUserSupplier()) {
            navigate("/ProductsPage");
        } else if (userAuth.isUserRetailer()) {
            navigate("/DashboardHome");
        } else if (userAuth.isUserServiceProvider()) {
            navigate("/DashboardHome");
        } else {
            navigate("/GetStarted/AccountDetails");
        }
    };

    const isUserAdmin = user.isAdmin || user.isSuperUser || user.isEmployee;

    if (
        !isUserAdmin &&
        user.hasSignedUp &&
        !user.isSupplier &&
        !user.isRetailer &&
        !user.isServiceProvider
    ) {
        return (
            <Navigate
                to={"/GetStarted/AccountTypeSelection"}
                state={{ referer: props.location }}
            />
        );
    }

    if (userAuth.isUserSupplier()) {
        return <SupplierProductsPage />;
    }

    if (isUserAdmin) {
        return (
            <DashboardContainer title={"Welcome"}>
                {/* <SupplierProduct /> */}

                <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                    <div className="max-w-sm w-full m-auto bg-white text-black shadow-lg p-3">
                        <p className="p-0 m-0 text-base">Hi {user.NameFirst},</p>
                        {user.isAdmin ||
                            (user.isSuperUser && (
                                <p className="p-0 m-0">
                                    Welcome to Bring Back Australia Dashboard
                                    for Admins
                                </p>
                            ))}
                        {user.isEmployee && (
                            <p className="p-0 m-0">
                                Welcome to Bring Back Australia Dashboard for
                                Employees
                            </p>
                        )}
                    </div>
                </div>
            </DashboardContainer>
        );
    }

    return (
        <DashboardContainer title={"Welcome"}>
            <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                <div className="max-w-sm w-full m-auto bg-white text-black shadow-lg p-3">
                    <h4>Hi {user.NameFirst},</h4>
                    {user.isApprovalBeingProcessed && (
                        <>
                            <span>Your application is being processed.</span>
                            <span>
                                We will let know via email once it has been
                                assessed.
                            </span>
                        </>
                    )}
                    {!user.isApprovalBeingProcessed && (
                        <>
                            <p className="text-lg">
                                Welcome to Bring Back Australia
                            </p>
                            <p className="text-right text-sm p-0 m-0">
                                To get started
                            </p>
                            <p className="text-right text-sm p-0 m-0">
                                click
                                <a href="/" onClick={getStarted}>
                                    {" "}
                                    here
                                </a>
                            </p>
                        </>
                    )}
                </div>
            </div>
        </DashboardContainer>
    );
};

export default DashboardHome;
