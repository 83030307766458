import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SideMenuItem from "../SideMenuItem";

const DetailItem = ({ segment, family, clss, brick, grouping, variant, detail }) => {

  const location = useLocation();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const gpc = window.location.pathname.replace('/ProductsPage/', '').split('/');
    if (!gpc[6] || !gpc[6].match(/^\d+$/g)) {
      setIsSelected(false);
    } else {
      setIsSelected(gpc[6] == detail.ID);
    }
  }, [location]);

  return (
    <SideMenuItem isSelected={isSelected}>
      <Link className="text-muted text-base" to={"/ProductsPage/" + segment.ID + "/" + family.ID + "/" + clss.ID + "/" + brick.ID + "/" + grouping.ID + "/" + variant.ID + "/" + detail.ID}>
        <span title={detail.ID + " : " + detail.Desc}>{detail.Desc ?? 'NOT DEFINED'}</span>
      </Link>
    </SideMenuItem>
  );
};

export default DetailItem;