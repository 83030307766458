import React, { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Auth } from "../../api/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";

const ResetPassword = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [succeeded, setSucceeded] = useState(false);
    const [failed, setFailed] = useState(null);
    const [pwDontMatch, setPwDontMatch] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
    const [passwordInput, setPasswordInput, onPasswordChange] =
        useFormFieldOnChange("");
    const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] =
        useFormFieldOnChange("");

    const onEmailInputChange = (e) => {
        onEmailChange(e);
        hideStatusMessages();
    };

    const onPasswordInputChange = (e) => {
        onPasswordChange(e);
        hideStatusMessages();
    };

    const onConfirmPwInputChange = (e) => {
        onConfirmPwChange(e);
        hideStatusMessages();
    };

    useEffect(() => {
        if (
            passwordInput.trim().length == "" ||
            confirmPwInput.trim().length == ""
        ) {
            setPwDontMatch(false);
            return;
        }
        setPwDontMatch(passwordInput !== confirmPwInput);
    }, [passwordInput, confirmPwInput]);

    const submitForm = async (e) => {
        e.preventDefault();

        if (processing) {
            return;
        }
        if (
            emailInput.trim().length == "" ||
            passwordInput.trim().length == "" ||
            confirmPwInput.trim().length == ""
        ) {
            return;
        }

        if (passwordInput !== confirmPwInput) {
            setPwDontMatch(true);
            return;
        }

        hideStatusMessages();

        setProcessing(true);
        const reqRes = await Auth().ResetPassword(
            emailInput,
            passwordInput,
            confirmPwInput,
            searchParams.get("token")
        );
        setProcessing(false);

        if (reqRes == false || reqRes.status != HTTP_STATUS_CODES.OK) {
            if (reqRes.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                setFailed(
                    <span>
                        Please, make sure you have entered a valid email address
                        <br />
                        and both passwords are the same.
                    </span>
                );
            } else if (reqRes.status == HTTP_STATUS_CODES.BAD_REQUEST) {
                setFailed(
                    "Please, make sure you have entered your email address correctly."
                );
            } else {
                setFailed(
                    "An error has occurred during the registration process."
                );
            }
            return;
        }

        resetForm();
        setSucceeded(true);
    };

    const hideStatusMessages = () => {
        setProcessing(false);
        setFailed(null);
        setSucceeded(false);
        setPwDontMatch(false);
    };

    const resetForm = () => {
        setEmailInput("");
        setPasswordInput("");
        setConfirmPwInput("");
        hideStatusMessages();
    };

    const passwordHasBeenChanged = () => {
        return (
            <>
                <p style={{ fontWeight: "normal" }}>
                    Please close this window and login to app.
                </p>
                {/* <div className="FormButtonContainer">
          <button
            onClick={() => {
              navigate("/SignIn");
            }}
          >
            Continue to login
          </button>
        </div> */}
            </>
        );
    };

    const formContent = () => {
        return (
            <>
                <div className="flex flex-col mb-3">
                    <label>Email</label>
                    <input
                        type="text"
                        placeholder="Enter your email..."
                        value={emailInput}
                        onChange={onEmailInputChange}
                        className="rounded-full border py-1.5 px-2"
                    />
                </div>
                <div className="flex flex-col mb-3">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password..."
                        value={passwordInput}
                        onChange={onPasswordInputChange}
                        className="rounded-full border py-1.5 px-2"
                    />
                </div>
                <div className="flex flex-col mb-3">
                    <label>Confirm your password</label>
                    <input
                        type="password"
                        placeholder="Confirm your password..."
                        value={confirmPwInput}
                        onChange={onConfirmPwInputChange}
                        className="rounded-full border py-1.5 px-2"
                    />
                </div>
                {failed != null && (
                    <p className="text-red-500 font-bold">{failed}</p>
                )}
                {pwDontMatch && (
                    <p className="text-red-500 font-bold">
                        You've entered different passwords!
                    </p>
                )}
                <div className="flex align-center gap-2 mt-3">
                    <button disabled={processing} onClick={submitForm} className="bg-primary rounded-full text-white py-1.5 w-full">
                        Confirm
                    </button>
                    <button
                        className={`rounded-full text-white py-1.5 w-full ${processing ? 'bg-gray-400' : 'bg-primary'}`}
                        disabled={processing}
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                        }}
                    >
                        Reset
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="w-full flex justify-center h-full items-center lg:px-0 px-5 overflow-hidden">
            <div className="w-1/3 mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
                <form>
                    <div className="flex flex-col justify-center items-center">
                        <img
                            className="w-[300px]"
                            src="BBA-Logo-TBl-S.png"
                            alt=""
                        />
                        <p className="font-bold text-center mb-3 text-lg">
                            {!succeeded
                                ? "Reset your password"
                                : "Your password has been successfully changed"}
                        </p>
                    </div>
                    {!succeeded ? formContent() : passwordHasBeenChanged()}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
