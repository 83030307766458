import React, { Fragment, useContext, useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
    submitIgnoresSubstitutes
} from "../../../../store/slices/gvdValidateSlice";
import "./RawWordsIgnoreSubstitute.scss";

const RawWordsIgnoreSubstitute = ({
    ignoresSubstitutes,
    wordMatch,
    onWordMatchUpdate,
    modalShow,
    handleClose,
}) => {
    const dispatch = useAppDispatch();
    const { processing } = useAppSelector(gvdValidate);
    const [formControl, setFormControl] = useState({});

    useEffect(() => {
        if (ignoresSubstitutes !== undefined) {
            setFormControl({ ...ignoresSubstitutes });
        }
    }, [ignoresSubstitutes]);

    const onIgnoredChange = (e, word) => {
        const currentState = formControl;
        currentState[word].Ignored = e.target.checked == true;
        currentState[word]["Substitute"] = [];
        setFormControl({ ...currentState });
    };

    const onSubstituteChange = (e, word, substituteField) => {
        const currentState = formControl;
        currentState[word].Ignored = false;
        currentState[word]["Substitute"][substituteField] = e.target.value;
        setFormControl({ ...currentState });
    };

    const submitPopUp = async () => {

        const json = await dispatch(submitIgnoresSubstitutes(
            wordMatch.WordMatchID,
            ignoresSubstitutes
        ));
        onWordMatchUpdate(json.data);
        handleClose();
    };

    const createRows = () => {
        const CreateNewRow = (word, key) => {
            return (
                <div
                    className="flex items-center gap-2"
                    key={key}
                >
                    <p className="w-1/5 truncate">{word}</p>
                    <input
                        checked={ignoresSubstitutes[word].Ignored}
                        onChange={(e) => onIgnoredChange(e, word)}
                        type="checkbox"
                        className="w-1/5"
                    />
                    <input
                        value={ignoresSubstitutes[word][0]}
                        maxLength={25}
                        onChange={(e) => onSubstituteChange(e, word, 0)}
                        type="text"
                        className="rounded w-1/5 py-1.5 px-2 border border-gray bg-white"
                    />
                    <input
                        value={ignoresSubstitutes[word][1]}
                        maxLength={25}
                        onChange={(e) => onSubstituteChange(e, word, 1)}
                        type="text"
                        className="rounded w-1/5 py-1.5 px-2 border border-gray bg-white mx-2"
                    />
                    <input
                        value={ignoresSubstitutes[word][2]}
                        maxLength={25}
                        onChange={(e) => onSubstituteChange(e, word, 2)}
                        type="text"
                        className="rounded w-1/5 py-1.5 px-2 border border-gray bg-white"
                    />
                </div>
            );
        };

        return wordMatch?.Words?.map((word, key) => CreateNewRow(word, key));
    };

    return (
        <Transition show={modalShow} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between pb-3 border-b border-b-black items-center">
                                    <h6 className="font-semibold text-sm">
                                        Edit the words
                                    </h6>
                                    <div
                                        onClick={handleClose}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex items-center mt-3 gap-2">
                                    <p className="w-1/5"></p>
                                    <p className="w-1/5 text-center">Ignore</p>
                                    <p className="w-1/5"></p>
                                    <p className="w-1/5">Substitute</p>
                                    <p className="w-1/5"></p>
                                </div>
                                {createRows()}
                                <div className="mt-3 border-t border-black flex justify-end">
                                    <button onClick={submitPopUp} className={`${processing ? 'bg-gray-500' : 'bg-primary'} rounded px-4 py-1.5 text-white mt-3`} disabled={processing}>Confirm</button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
export default RawWordsIgnoreSubstitute;
