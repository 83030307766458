import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { getBrickDetails, getGroups, getGroupVars, getGroupVarDetails, newGVD, assignGVDToWordMatch, getAssignedWords, getUnassignedWords, listIgnoresSubstitutesForWordMatch, listProductsRelatedToWordMatch, processIgnoresSubstitutes } from '../../api/GroupVariantDetail';

export interface GvdValidateState {
  variants: any;
  words: any;
  rawWords: any;
  modalProps: any;
  proModalProps: any;
  assignVariants: any;
  groups:  any;
  assignGroups:  any;
  details: any;
  assignDetails: any;
  brickSelected: any;
  processing: boolean;
  loading: boolean;
  errorMsg: object;
}

export const initialState: GvdValidateState = {
  variants: [],
  words: [],
  rawWords: [],
  assignVariants: [],
  details: [],
  assignDetails: [],
  brickSelected: {},
  modalProps: {},
  proModalProps: {},
  processing: false,
  loading: false,
  errorMsg: null,
  groups: [],
  assignGroups: [],
};

export const gvdValidateSlice = createSlice({
  name: "gvdValidate",
  initialState,
  reducers: {
    setErrorMsg: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setBrickSelected: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.brickSelected = payload;
    },
    setLoading: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setVariants: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.variants = payload;
    },
    setWords: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.words = payload;
    },
    setRawWords: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.rawWords = payload;
    },
    setAssignVariants: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.assignVariants = payload;
    },
    setProcessing: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setDetails: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.details = payload;
    },
    setAssignDetails: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.assignDetails = payload;
    },
    setGroups: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.groups = payload;
    },
    setAssignGroups: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.assignGroups = payload;
    },
    setModalProps: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.modalProps = payload;
    },
    setProModalProps: (state: GvdValidateState, { payload }: PayloadAction<any>) => {
      state.proModalProps = payload;
    },
  },
});

export const brickSearch = (brickInput) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const json = await getBrickDetails(brickInput);
  if (json.status == 200) {
    if (json.data.bricks.length == 0) return;
    dispatch(setBrickSelected({ BRICK: json.data.bricks[0].BrickCode, BrickDesc: json.data.bricks[0].BrickDesc }))
  }
  dispatch(setLoading(false));
  return json;
};

export const fetchGroups = (BRICK) => async (dispatch: any) => {
  dispatch(setGroups([]))
  const json = await getGroups(BRICK);
    if (json.status == 200) {
      dispatch(setGroups(json.data))
    } else {
      return;
    }
};

export const fetchAssignGroups = (BRICK) => async (dispatch: any) => {
  const json = await getGroups(BRICK);
    if (json.status == 200) {
      dispatch(setAssignGroups(json.data))
    } else {
      return;
    }
};

export const fetchGroupVars = (BRICK, GroupingID) => async (dispatch: any) => {
  const json = await getGroupVars(BRICK, GroupingID);
    if (json.status == 200) {
      dispatch(setVariants(json.data));
    } else {
      return;
    }
  return json;
};

export const fetchAssignGroupVars = (BRICK, selectedGroup) => async (dispatch: any) => {
  const json = await getGroupVars(BRICK, selectedGroup);
    if (json.status == 200) {
      dispatch(setAssignVariants(json.data));
    } else {
      return;
    }
  return json;
};

export const fetchVariantData = (BRICK, GroupingID, VariantID) => async (dispatch: any) => {
  const json = await getGroupVarDetails(BRICK, GroupingID, VariantID);
    if (json.status == 200) {
      dispatch(setDetails(json.data));
    } else {
      return;
    }
  return json;
};

export const fetchAssignVariantData = (BRICK, selectedGroup, selectedVariant) => async (dispatch: any) => {
  const json = await getGroupVarDetails(BRICK, selectedGroup, selectedVariant);
    if (json.status == 200) {
      dispatch(setAssignDetails(json.data));
    } else {
      return;
    }
  return json;
};

export const newGVDAssign = (BRICK, group, variant, detail) => async (dispatch: any) => {
  const json = await newGVD(BRICK, group, variant, detail);
  if (json.status !== 200) {
    return;
  }
  return json;
};

export const assignGVDToWord = (BRICK, group, variant, detail, VWMID) => async (dispatch: any) => {
  dispatch(setProcessing(true));
  const json = await assignGVDToWordMatch(BRICK, group, variant, detail, VWMID);
  if (json.status !== 200) {
    return;
  }
  dispatch(setProcessing(false));
  return json;
};

export const fetchAssignedWords = (BRICK, GroupingID, VariantID, DetailID) => async (dispatch: any) => {
  dispatch(setWords([]));
  dispatch(setProcessing(true));
  const json = await getAssignedWords(BRICK, GroupingID, VariantID, DetailID);
  if (json.status == 200) {
    dispatch(setWords(json.data));
  } else {
    return;
  }
  dispatch(setProcessing(false));
};

export const fetchUnassignedWords = (BRICK, rawWordsSearchBox) => async (dispatch: any) => {
  dispatch(setWords([]));
  dispatch(setProcessing(true));
  const json = await getUnassignedWords(BRICK, rawWordsSearchBox);
  if (json.status == 200) {
    dispatch(setRawWords(json.data));
  } else {
    return;
  }
  dispatch(setProcessing(false));
};

export const fetchListProducts = (WordMatchID, wordMatch) => async (dispatch: any) => {
  dispatch(setProcessing(true));
  const json = await listProductsRelatedToWordMatch(WordMatchID);
  if (json.status == 200) {
    dispatch(setProModalProps({
      wordMatch,
      prods : json.data
    }));
  } else {
    return;
  }
  dispatch(setProcessing(false));
};

export const fetchIgnoreSubstitute = (WordMatchID, wordMatch, onWordMatchUpdate) => async (dispatch: any) => {
  dispatch(setProcessing(true));
  const json = await listIgnoresSubstitutesForWordMatch(WordMatchID);
  dispatch(setProcessing(false));
  return json;
};

export const submitIgnoresSubstitutes = (WordMatchID, ignoresSubstitutes) => async (dispatch: any) => {
  dispatch(setProcessing(true));
  const json = await processIgnoresSubstitutes(WordMatchID, ignoresSubstitutes);
  dispatch(setProcessing(false));
  return json;
};

export const {
  setBrickSelected,
  setLoading,
  setErrorMsg,
  setVariants,
  setProcessing,
  setDetails,
  setGroups,
  setAssignVariants,
  setAssignGroups,
  setAssignDetails,
  setWords,
  setRawWords,
  setModalProps,
  setProModalProps
} = gvdValidateSlice.actions;

export const gvdValidate = (state: RootState) => state.gvdValidate;

export default gvdValidateSlice.reducer;
