import React, { useState, useEffect, Fragment } from "react";
import { HTTP_STATUS_CODES } from "../../../api/API";
import DashboardPopUp from "../../../dashboard-components/DashboardPopUp";
import { BarcodeInput, BarcodeSearchButton } from "./ProductScanNewStyle";
import { getProductBrickGVD } from "./ReclassifyAPI";
import { useFormFieldOnChange } from "../../../utils/FormHooks";
import ReclassifyDropdownsModal from "./ReclassifyDropdownsModal";
// import barcodeValidator from "barcode-validator";
import { Transition, Dialog } from "@headlessui/react";
import validbarcode from "barcode-validator";
import { isValid } from "barcode-validator";
const ErrorMessage = ({ message }) => {
    return (
        message?.trim().length > 0 && (
            <span className="text-red-500 font-bold text-sm">{message}</span>
        )
    );
};

const ReclassifyModal = ({
    productInfo,
    refreshCallback,
    showClassifyModal,
    closeClassifyModal,
}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [GTINinput, , onGTINInputChange] = useFormFieldOnChange("");

    const [productScanned, setProductScanned] = useState(null);
    const [showPopUpModal, setShowPopUpModal] = useState(false);
    const [active, setActive] = useState(false);

    const handleSelectedRef = (value) => {
        alert("value is ");
    };
    // function responsible for comparing the product like for like
    const listProductDetails = async () => {
        if (!GTINinput.trim().length) return;

        // validate GTIN before sending request
        const validGTIN = validbarcode(GTINinput.trim());
        // The if statement will be used to check if the barcode is valid or not
        if (validGTIN) {
            setLoading(true);
            const res = await getProductBrickGVD(GTINinput);
            console.log("res", res);
            setLoading(false);
            setShowPopUpModal(true);

            setErrorMessage(null);
            if (res.status == HTTP_STATUS_CODES.NOT_FOUND) {
                setErrorMessage("Product Not On File.");
                return;
            } else if (res.status !== HTTP_STATUS_CODES.OK) {
                setErrorMessage("An error has occurred.");
                return;
            }

            setProductScanned(res?.data && res?.data);
            console.log("productScanned", res.data);
            console.log("you entered a valid GTIN");
        } else {
            setErrorMessage("Please enter a valid barcode.");
        }
    };

    useEffect(() => {
        console.log("mountd");
        return () => {};
    }, []);
    const SearchContainer = () => {
        const onSubmitSearchContainer = (e) => {
            e.preventDefault();

            if (!GTINinput) {
                // alert("Search");
                setProductScanned("");

                setShowPopUpModal(true);
            }
            // setLoading(true);

            listProductDetails();
            // setProductScanned(productScanned);
            // setShowPopUpModal(true);
        };

        return (
            productScanned == null && (
                <>
                    <form
                        onSubmit={onSubmitSearchContainer}
                        className="flex justify-between items-center pb-3 gap-2"
                    >
                        <input
                            value={GTINinput}
                            onChange={onGTINInputChange}
                            autoFocus
                            placeholder="Enter the Barcode/GTIN"
                            className="w-full rounded py-1.5 px-3 border border-gray"
                            type="text"
                        />
                        <button className="bg-primary text-white rounded px-3 py-1.5">
                            Search
                        </button>
                    </form>
                    <ErrorMessage message={errorMessage} />
                </>
            )
        );
    };

    return (
        // <DashboardPopUp
        //   processing={loading}
        //   header={"Categorise This Product"}
        //   description={
        //     productScanned === null ? (
        //       <p>
        //         - Enter the barcode of a Like-for-Like Product
        //         <br />- Press Search without a barcode to open Categorise screen
        //       </p>
        //     ) : (
        //       <p>Select the Categories from the Drop Down Lists</p>
        //     )
        //   }
        // >
        //   <SearchContainer />
        //   {productScanned != null && (
        //     <ReclassifyDropdownsModal
        //       refreshCallback={refreshCallback}
        //       productScanned={productScanned}
        //       productToBeUpdated={productInfo}
        //       setErrorMessage={setErrorMessage}
        //       setLoading={setLoading}
        //       active={active}
        //       handleSelectedRef={handleSelectedRef}
        //     >
        //       <ErrorMessage message={errorMessage} />
        //     </ReclassifyDropdownsModal>
        //   )}
        // </DashboardPopUp>

        <Transition appear show={showClassifyModal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closeClassifyModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between border-b border-b-black pb-3 items-start">
                                    <div>
                                        <h5 className="font-semibold text-sm">
                                            Categorise This Product
                                        </h5>
                                        {productScanned === null ? (
                                            <p>
                                                - Enter the barcode of a
                                                Like-for-Like Product
                                                <br />- Press Search without a
                                                barcode to open Categorise
                                                screen
                                            </p>
                                        ) : (
                                            <p>
                                                Select the Categories from the
                                                Drop Down Lists
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        onClick={closeClassifyModal}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <SearchContainer />
                                </div>
                                <div className="mt-3">
                                    {productScanned != null && (
                                        <ReclassifyDropdownsModal
                                            refreshCallback={refreshCallback}
                                            productScanned={productScanned}
                                            productToBeUpdated={productInfo}
                                            setErrorMessage={setErrorMessage}
                                            setLoading={setLoading}
                                            closeClassifyModal={closeClassifyModal}
                                            active={active}
                                            handleSelectedRef={
                                                handleSelectedRef
                                            }
                                        >
                                            <ErrorMessage
                                                message={errorMessage}
                                            />
                                        </ReclassifyDropdownsModal>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ReclassifyModal;
