import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import SideMenuAdmin from "./SideMenuAdmin";
import SideMenuRetailer from "./SideMenuRetailer";
import SideMenuServiceProvider from "./SideMenuServiceProvider";
import SideMenuSupplier from "./SideMenuSupplier";
import SideMenuItem from "./SideMenuItem";
import "../assets/css/metisMenu.min.css";
import MetisMenu from "@metismenu/react";

const SideMenu = ({sideBarToggle,responsiveToggle}) => {
    const userAuth = useAuthContext();
    const user = userAuth.user;

    const getMenuItems = () => {
        return (
            <>
                <SideMenuItem>
                    <li className="Ul_li--hover mx-3 h-9 flex items-center">
                        <Link to={"/DashboardHome"}>
                            <i className="i-Bar-Chart text-lg mr-2 text-muted"></i>
                            <span className="item-name text-base text-muted">
                                DASHBOARD
                            </span>
                        </Link>
                    </li>
                </SideMenuItem>
                {getMenuToDisplay()}
            </>
        );
    };

    const isUserAdmin =
        !user || user.isAdmin || user.isSuperUser || user.isEmployee;

    const getMenuToDisplay = () => {
        if (!user) {
            return null;
        }

        if (isUserAdmin) {
            return <SideMenuAdmin />;
        }

        if (userAuth.isUserSupplier()) {
            return <SideMenuSupplier />;
        }

        if (userAuth.isUserRetailer()) {
            return <SideMenuRetailer />;
        }

        if (userAuth.isUserServiceProvider()) {
            return <SideMenuServiceProvider />;
        }

        return null;
    };


    const boxShadowStyle = {
        boxShadow: '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)',
    };

    return (
        <div className={`h-screen fixed top-0 ${sideBarToggle ? 'lg:-left-60' : 'lg:left-0'} ${responsiveToggle ? 'left-0' : '-left-60'} w-60 z-[1000] bg-white transition-all duration-200 ease-in`} style={boxShadowStyle}>
            <div className="pr-3 text-center mt-4 mb-2 flex justify-center items-center">
                <img
                    className="pl-3 w-full object-contain"
                    src="/BBA-Logo-TBl.png"
                />
            </div>

            <div className="h-[calc(100vh-64px)] overflow-y-auto">
                <div className="side-nav">
                    <div className="main-menu">
                        <MetisMenu className="space-y-4">{user && getMenuItems()}</MetisMenu>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;
