import { combineReducers } from '@reduxjs/toolkit';

import getStarted from './slices/getStartedSlice';
import signupApproval from './slices/signupApprovalSlice';
import productsPage from './slices/productsPageSlice';
import gvdEditior from './slices/gvdEditiorSlice';
import gvdValidate from './slices/gvdValidateSlice';
import product from './slices/productSlice';
import auth from './slices/authSlice';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  getStarted,
  signupApproval,
  productsPage,
  gvdEditior,
  gvdValidate,
  product,
  auth
});

export default rootReducer;
