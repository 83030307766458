import DashboardContainer from "../../dashboard-components/DashboardContainer";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import ReactDataGrid, { filterTypes } from "@inovua/reactdatagrid-enterprise";
import { HTTP_STATUS_CODES } from "../../api/API";
import {
	productsPage,
	setActive,
	fetchData,
	setDataSource,
} from "../../store/slices/productsPageSlice";
import styled from "styled-components";
import "@inovua/reactdatagrid-enterprise/index.css";
import defaultColumns from "./productGridColumns";
import defaultFilterValue from "./productGridFilters";

export const PleaseSelectLevel = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	font-size: 17px;
	font-weight: bold;
	font-style: italic;
`;

const ProductsPage = () => {
	const dispatch = useAppDispatch();
	const { active, dataSource } = useAppSelector(productsPage);

	useEffect(() => {
		loadData();
	}, [active]);

	const loadData = () => {
		const fetchApiData = async ({
			skip,
			limit,
			sortInfo,
			filterValue
		}) => {
			skip = Math.round(Math.max(skip - 1, 0) / limit) + 1;
			const res = await dispatch(
				fetchData(skip, limit, sortInfo, active, filterValue)
			);

			if (res.status !== HTTP_STATUS_CODES.OK) {
				return Promise.resolve({ data: [], count: 0 });
			}
			return Promise.resolve({
				data: res.data.data,
				count: res.data.total,
			});
		};
		dispatch(setDataSource(fetchApiData));
	};

	return (
		<DashboardContainer title={"Product List"}>
			<>
				<div className="bg-gray-200 p-3 rounded">
					<div className="flex justify-start gap-3 items-center">
						<button
							onClick={() => dispatch(setActive(1))}
							className={`${
								active === 1
									? "bg-primary hover:bg-lightPrimary text-white"
									: "bg-gray-500  text-white hover:bg-gray-600"
							} w-full ripple uppercase rounded border border-gray-300 px-4 py-2`}
						>
							gs1
						</button>

						<button
							onClick={() => dispatch(setActive(2))}
							className={`${
								active === 2
									? "bg-primary hover:bg-lightPrimary text-white"
									: "bg-gray-500  text-white hover:bg-gray-600"
							} w-full ripple uppercase rounded border border-gray-300 px-4 py-2`}
						>
							Categorised
						</button>

						<button
							onClick={() => dispatch(setActive(3))}
							className={`${
								active === 3
									? "bg-primary hover:bg-lightPrimary text-white"
									: "bg-gray-500  text-white hover:bg-gray-600"
							} w-full ripple uppercase rounded border border-gray-300 px-4 py-2`}
						>
							Non-Categorised
						</button>
					</div>
					<ReactDataGrid
						licenseKey={
							"AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2022-10-27,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=20321614271346880205244607387-2083892569-3379015141489805940"
						}
						idProperty="ProductID"
						style={{ marginTop: 10, minHeight: 550 }}
						columns={defaultColumns}
						defaultFilterValue={defaultFilterValue}
						filterTypes={filterTypes}
						pagination="remote"
						checkboxColumn={true}
						columnUserSelect={true}
						dataSource={dataSource}
						limit={20}
					/>
					<div className="bg-black h-px w-full my-4" />
					<div className="text-base">
						{active == 1 && <p>This page shows your Product Data (GS1).</p>}
						{active == 2 && (
							<p>This page shows your Product Data (Categorised).</p>
						)}
						{active == 3 && (
							<p>This page shows your Product Data (Non-Categorised).</p>
						)}
					</div>
				</div>
			</>
		</DashboardContainer>
	);
};

export default ProductsPage;
